import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import FontSizeChanger from "react-font-size-changer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import alertify from "alertifyjs";
import { useCookies } from "react-cookie";
import "alertifyjs/build/css/alertify.css";
import BarTop from "../components/BarTop";
import BarBottom from "../components/BarBottom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const enableServingPeriod = parseInt(
  process.env.REACT_APP_ENABLE_SERVING_PERIOD
);
const enableRevenueCenter = parseInt(
  process.env.REACT_APP_ENABLE_REVENUE_CENTER
);
const loginCustomerNumber = parseInt(
  process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER
);
let enableNewUI = process.env.REACT_APP_NEWUI_LW;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const backgroundColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const hoverColor = process.env.REACT_APP_TEXT_HIGHLIGHT;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <br></br>
      Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by<br></br>
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img
          className="silogo"
          src={siteMedSrc + "si-logo" + ".svg"}
          alt="ServingIntel"
        />
        <br></br>
        <br></br>
      </Link>{" "}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

const theme = createTheme();

export const LoginCode = () => {
  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const [newFontSize, setFontSize] = useState(16);
  const increaseFont = () => setFontSize(newFontSize + 2);

  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");
  const roomNumber = sessionStorage.getItem("roomNumber");

  const [pinCode, setPinCode] = useState("");
  const [pinCodeConfirm, setPinCodeConfirm] = useState();
  const location = useLocation();

  const [numpadVisible, setNumpadVisible] = useState(false);
  const [layoutPIN, setLayoutPIN] = useState("default");
  const pinInputRef = useRef(null);
  const [caretPosition, setCaretPosition] = useState(0);

  // Function to handle clicks outside the TextField and Keyboard
  const handleClickOutside = (event) => {
    if (pinInputRef.current && !pinInputRef.current.contains(event.target)) {
      setNumpadVisible(false);
    }
  };

  useEffect(() => {
    // Add event listener to handle clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOnNumPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShiftPIN();
    } else if (button === "{enter}") {
      setNumpadVisible(false);
    } else if (button === "{bksp}") {
      if (caretPosition > 0) {
        setPinCode((prev) => {
          const newValue =
            prev.slice(0, caretPosition - 1) + prev.slice(caretPosition);
          setCaretPosition(caretPosition - 1); // Move caret back after deleting
          return newValue;
        });
      }
    } else if (button === "{space}") {
      setPinCode((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + " " + prev.slice(caretPosition); // Add a blank space
        setCaretPosition(caretPosition + 1);
        return newValue;
      });
    } else {
      setPinCode((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + button + prev.slice(caretPosition);
        setCaretPosition(caretPosition + 1); // Move caret forward after adding
        return newValue;
      });
    }
  };

  const handleShiftPIN = () => {
    setLayoutPIN(layoutPIN === "default" ? "shift" : "default");
  };

  const handleFocusPIN = () => {
    setNumpadVisible(true);
    setTimeout(() => {
      if (pinInputRef.current) {
        pinInputRef.current.querySelector("input").focus(); // Focus the input field
      }
    }, 0);
  };

  const handleCaretChange = (event) => {
    setCaretPosition(event.target.selectionStart);
  };

  const numpadLayout = {
    default: [
      "1 2 3 4 5 6 7 8 9 0",
      "q w e r t y u i o p {bksp}",
      "a s d f g h j k l {enter}",
      "{shift} z x c v b n m , . {shift}",
      "{space}",
    ],
    shift: [
      "1 2 3 4 5 6 7 8 9 0",
      "Q W E R T Y U I O P {bksp}",
      "A S D F G H J K L {enter}",
      "{shift} Z X C V B N M , . {shift}",
      "{space}",
    ],
  };

  const [cookie, setCookie, removeCookie] = useCookies();

  const changePin = () => {
    removeCookie("firstName");
    removeCookie("roomNumber");
    history.push("/updatecode");
  };

  const logOut = () => {
    removeCookie("firstName");
    removeCookie("roomNumber");
    sessionStorage.removeItem("pinCode");
    history.push("/");
  };

  const notMe = () => {
    removeCookie("firstName");
    removeCookie("roomNumber");
    sessionStorage.removeItem("pinCode");
    history.push("/notme");
  };

  useEffect(() => {
    if (
      parseInt(process.env.REACT_APP_LOGIN_ROOM_NUMBER) === 1 ||
      parseInt(sessionStorage.getItem("isAdminLogin")) === 1
    ) {
      sessionStorage.setItem("pinCodeOk", 1);
      if (enableRevenueCenter === 1) {
        history.push("/welcome");
      } else {
        history.push("/menu");
      }
    }
  }, []);

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/sync-to-menu-info/" +
      serverId +
      "/" +
      siteId;
    axios
      .get(url)
      .then((response) => {
        const data = response.data;
      })
      .catch((error) => {
        return;
      });
  }, []);

  const validateAccount = () => {
    // console.log(firstName)
    // console.log(roomNumber)

    if (!pinCode) {
      alertify.alert("Error", "Please input PIN CODE", function () {});
      return;
    }

    const url =
      loginCustomerNumber == 1
        ? "https://ecommv2.servingintel.com/customer/login-customer-number/" +
          serverId +
          "/" +
          siteId +
          "/" +
          firstName +
          "/" +
          roomNumber
        : "https://ecommv2.servingintel.com/customer/login/" +
          serverId +
          "/" +
          siteId +
          "/" +
          storeId +
          "/" +
          firstName +
          "/" +
          roomNumber;
    axios
      .get(url)
      .then((response) => {
        console.log(response);
        if (response.data === null || response.data.length == 0) {
          alertify.alert("Error", "Invalid PIN CODE", function () {});
          return;
        }

        const customer = response.data;
        if (String(customer.SIC_WebPin) === String(pinCode)) {
          // && String(customer.SIC_PIN) === String(pinCodeConfirm)
          sessionStorage.setItem("pinCodeOk", 1);
          // sessionStorage.setItem('pinCodeConfirm',customer.SIC_PIN)
          if (enableServingPeriod === 1 || enableRevenueCenter === 1) {
            if (sessionStorage.getItem("categoryUrl")) {
              const redirectUrl = sessionStorage.getItem("categoryUrl");
              sessionStorage.removeItem("categoryUrl");
              window.location.href = redirectUrl;
            } else {
              history.push("/welcome");
            }
          } else {
            history.push("/menu");
          }
        } else {
          alertify.alert("Error", "Invalid PIN CODE", function () {});
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {restaurantName}: {tagline}
          </title>
        </Helmet>
      </HelmetProvider>
      <ThemeProvider theme={theme}>
        <Grid
          id="LoginCode"
          container
          component="main"
          justifyContent={enableKiosk === "1" ? "center" : null}
          alignItems={enableKiosk === "1" ? "center" : null}
          sx={{
            fontSize: enableKiosk === "0" ? `${newFontSize}px` : null,
            backgroundImage:
              enableKiosk === "1" ? `url(${siteMedSrc}signbg.webp)` : null,
            backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: enableKiosk === "1" ? "cover" : "cover",
            backgroundPosition: enableKiosk === "1" ? "left" : "left",
            left: enableKiosk === "1" ? "0" : "0",
            right: enableKiosk === "1" ? "0" : "0",
            minHeight: "100vh",
            height: enableKiosk === "0" ? "100vh" : null,
          }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={enableKiosk === "1" ? false : 4}
            md={enableKiosk === "1" ? false : 7}
            sx={{
              backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: enableKiosk === "1" ? "cover" : "cover",
              backgroundPosition: enableKiosk === "1" ? "left" : "left",
              left: enableKiosk === "1" ? "0" : "0",
              right: enableKiosk === "1" ? "0" : "0",
              backgroundImage:
                enableKiosk === "0" ? `url(${siteMedSrc}signbg.webp)` : null,
            }}
          />

          <Grid
            item
            xs={enableKiosk === "1" ? 10 : 12}
            sm={enableKiosk === "1" ? 10 : 8}
            md={enableKiosk === "1" ? 10 : 5}
            justifyContent="center"
            alignItems="center"
            component={Paper}
            elevation={6}
            square
          >
            <Box
              id="target"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {enableNewUI == 1 ? (
                <img
                  src={siteMedSrc + "logo" + ".webp"}
                  className="SL-logo02"
                  alt={`${restaurantName} logo`}
                />
              ) : enableNewUI == 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={siteMedSrc + "logo" + ".webp"}
                    width={logoWidth}
                    alt="logo"
                    style={{
                      transform: "scale(2)",
                      paddingTop: ".5rem",
                      paddingBottom: "1rem",
                    }}
                  />
                </div>
              ) : (
                // Add a default case here, for example:
                <img
                  src={siteMedSrc + "logo" + ".webp"}
                  className="Default-logo"
                  alt={`${restaurantName} logo`}
                />
              )}
              <br />
              <Typography
                className="content"
                sx={{
                  marginTop: 1,
                  textAlign: "center",
                  lineHeight: 1.1,
                  fontWeight: "bold",
                  fontSize:
                    enableKiosk === "0"
                      ? "1.5rem !important"
                      : "1.75rem !important",
                }}
              >
                Welcome back! <br /> {firstName.toUpperCase()} <br /> It's nice
                to see you!
              </Typography>
              <br />
              <Typography
                className="content"
                sx={{
                  marginTop: 2,
                  textAlign: "center",
                  lineHeight: 1.1,
                  fontSize:
                    enableKiosk === "0"
                      ? "1.25rem !important"
                      : "1.5rem !important",
                }}
              >
                Please enter your PIN (4 Digit)
              </Typography>

              <Box
                className="content"
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: -2 }}
              >
                <div ref={pinInputRef}>
                  <TextField
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    name="pinCode"
                    label="Enter Pin"
                    id="pinCode"
                    onFocus={handleFocusPIN}
                    inputProps={{ maxLength: 4 }}
                    variant="standard"
                    value={pinCode}
                    onClick={handleCaretChange} // Update caret position on click
                    onKeyUp={handleCaretChange} // Update caret position on keyup
                    InputLabelProps={{
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                        marginTop: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                      },
                    }}
                    sx={{ pt: 1.5 }}
                    onChange={(e) => setPinCode(e.target.value)}
                  />
                  {numpadVisible && parseInt(enableKiosk) === 1 && (
                    <Keyboard
                      className="eKioskInput"
                      layout={numpadLayout}
                      layoutName={layoutPIN}
                      onKeyPress={handleOnNumPress}
                      inputName="pinCode"
                    />
                  )}
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 2,
                    fontWeight: "600",
                    backgroundColor: buttonColor,
                    "&:hover": {
                      backgroundColor: hoverColor,
                    },
                  }}
                  onClick={() => {
                    validateAccount();
                  }}
                >
                  <Typography
                    className="content"
                    style={{
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                      fontWeight: "600",
                    }}
                  >
                    LOGIN
                  </Typography>
                </Button>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 0,
                    mb: 2,
                    fontWeight: "600",
                    backgroundColor: buttonColor,
                    "&:hover": {
                      backgroundColor: hoverColor,
                    },
                  }}
                  onClick={() => {
                    notMe();
                  }}
                >
                  <Typography
                    className="content"
                    style={{
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                      fontWeight: "600",
                    }}
                  >
                    NOT {firstName} {lastName}?
                  </Typography>
                </Button>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 0,
                    mb: 2,
                    fontWeight: "600",
                    backgroundColor: buttonColor,
                    "&:hover": {
                      backgroundColor: hoverColor,
                    },
                  }}
                  onClick={() => {
                    changePin();
                  }}
                >
                  <Typography
                    className="content"
                    style={{
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                      fontWeight: "600",
                    }}
                  >
                    Forgot / Change PIN
                  </Typography>
                </Button>
              </Box>
            </Box>

            {enableNewUI === "0" && enableKiosk === "0" ? (
              <Copyright
                id="copyrignt"
                position="centered"
                sx={{ top: "auto", bottom: 0 }}
              />
            ) : null}
            {enableKiosk === "0" ? (
              <FontSizeChanger
                targets={["#target .content"]}
                onChange={(element, newValue, oldValue) => {
                  console.log(element, newValue, oldValue);
                }}
                options={{
                  stepSize: 2,
                  range: 5,
                }}
                customButtons={{
                  up: (
                    <AppBar
                      position="fixed"
                      sx={{
                        width: "90px",
                        boxShadow: 0,
                        top: "auto",
                        bottom: 237.5,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton aria-label="add font size">
                          <Fab
                            color="primary"
                            aria-hidden="true"
                            sx={{ backgroundColor: "#3D5309" }}
                          >
                            <AddCircleOutlineIcon />
                          </Fab>
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  ),
                  down: (
                    <AppBar
                      position="fixed"
                      sx={{
                        width: "90px",
                        boxShadow: 0,
                        top: "auto",
                        bottom: 180,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton>
                          <Fab
                            color="primary"
                            aria-hidden="true"
                            sx={{ backgroundColor: "#765F00" }}
                          >
                            <RemoveCircleOutlineIcon />
                          </Fab>
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  ),
                }}
              />
            ) : null}
          </Grid>
        </Grid>
        {enableNewUI === "1" ? (
          <>
            <BarTop />
            <BarBottom />
          </>
        ) : null}
      </ThemeProvider>
    </>
  );
};
